import React from "react";
import "./index.css"

function NewsLetter() {

  return (
    <div className="w-full">
      <div className="container relative bg-news mx-auto ">
      <div className="absolute w-full p-2 top-[75%] left-[50%]
       translate-x-[-50%] translate-y-[-50%]">
            <h2 className="text-center uppercase ">Enquiry now</h2>
            <p className="text-center text-sm">
            If you have any questions about our courses, admission process, or anything else, feel free to reach out. We're here to help you on your academic journey.
            </p>
            <div className="flex md:justify-center">
              <input type="text" placeholder="Your Email Here"   
              className="w-[70%] lg:w-[50%] p-2 border focus:outline-none"
              />
              <button type="button" className="text-white bg-blue-700
               hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 
               font-medium text-sm px-5 2 dark:bg-blue-600
                dark:hover:bg-blue-700 focus:outline-none
                 dark:focus:ring-blue-800">
                <a href="">Send</a>
                 </button>
            </div>
      </div>
      </div>
    </div>
  );
}

export default NewsLetter;

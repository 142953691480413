import React from 'react'
import HomeBanner from './home-banner'
import NumberReport from './number-report'
import OverTeamExtra from './overteam-extra'
import WhatMakeUs from './whatmakeus'
import NewsSlider from './newsSlider'
import OurTeam from './our-team'
import Faq from './faq'
import OneStopSolution from './oneStopSolution'
import CourseType from './courseType'

const Home = () => {
  return (
    <div>

        <HomeBanner/>
        <NumberReport/>
        <OverTeamExtra/>
        <WhatMakeUs/>
        <NewsSlider/>
        <OurTeam/>
        <Faq/>
        <OneStopSolution/>
    </div>
  )
}

export default Home;
import React from 'react';
import {Link} from 'react-router-dom';

const AboutReg = () => {
    return (
        <div className='w-full'>
            <div className='container mx-auto rounded-lg shadow-2xl'>
                <div className='py-10 px-5'>
                       <h6 className='py-2 text-2xl font-bold'>Learn by connecting practice</h6>
                    <div className='flex items-center justify-between flex-wrap'>
                        <h2 className='w-full md:w-[60%] text-2xl md:text-4xl font-thin'>Promoting High Quality Learning Of Young Children</h2>
                        <button type="button" className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 my-2 md:my-0 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900"><Link to ='/contact'>Apply Now</Link></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutReg;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parulgate from "../../context/images/parulUniversity/parulgate.jpg";
import quantumgate from "../../context/images/qunatumUniversity/quantumgate.jpg";
import pacificgate from "../../context/images/pacificUniversity/pacificgate.jpg";
import marwadigate from "../../context/images/marwadiUniversity/marwadigate.webp";
import karnawatigate from "../../context/images/karnawatiUniversity/karnawatigate.webp";
import spsugate from "../../context/images/spsuUniversity/spsugate.webp";
import haridwargate from "../../context/images//haridwarUniversity/roorkee_college_new.webp";
import knmodigate from "../../context/images/knmodijaipur/knmodigate.webp";
import iconindoregate from "../../context/images/iconIndore/icongate.jpg";
import raiahemdabadgate from "../../context/images/raiUniversityAhemdabad/raiahemdabadgate.jpeg";
import raibengloregate from "../../context/images/raiUniversityBenglore/raibengloregate.png";

import {
  AiFillStar,
  AiOutlineStar,
  AiOutlineCloudDownload,
  AiOutlineVideoCamera,
  AiFillAudio,
  AiOutlineSearch,
} from "react-icons/ai";
function AllUniversity() {
  const [selected, setSelected] = useState(null);
  // ALl Data Show
  function allbtn() {
    SetViewAll(bestCollege);
  }
  const finacebtn = (value) => {
    setSelected(value);
    let finaceArra = bestCollege.filter((elem) => {
      if (elem.category == value) {
        return true;
      } else {
        return false;
      }
    });
    SetViewAll(finaceArra);
  };
  const [bestCollege, SetBestCollege] = useState([
    {
      category: "Data Science",
      clgImg: quantumgate,
      clgHeading: "Quantum University Online",
      clgRateing: [
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiOutlineStar className="text-yellow-400" />,
      ],
      clgListen: [<AiFillAudio />, "Listen Podcast"],
      clgVideo: [<AiOutlineVideoCamera />, "Watch Video"],
      clgPrice: "₹1,66,000",
      clgInfoDownload: [<AiOutlineCloudDownload />, "Download Propectus"],
      clgCompare: "Add to compare",
      clgMore: "More",
      clgCourse: "UGC, AICTE, NIRF, WES, NAAC, A+",
      id: "quantum-university",
    },
    {
      category: "International Business Management",
      clgImg: parulgate,
      clgHeading: "Parul University",
      clgRateing: [
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiOutlineStar className="text-yellow-400" />,
      ],
      clgListen: [<AiFillAudio />, "Listen Podcast"],
      clgVideo: [<AiOutlineVideoCamera />, "Watch Video"],
      clgPrice: "₹1,66,000",
      clgInfoDownload: [<AiOutlineCloudDownload />, "Download Propectus"],
      clgCompare: "Add to compare",
      clgMore: "More",
      clgCourse: "UGC, AICTE, NIRF, WES, NAAC, A+",
      id: "parul-university",
    },
    {
      category: "Finance Management",
      clgImg: pacificgate,
      clgHeading: "Pacific University",
      clgRateing: [
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiOutlineStar className="text-yellow-400" />,
      ],
      clgListen: [<AiFillAudio />, "Listen Podcast"],
      clgVideo: [<AiOutlineVideoCamera />, "Watch Video"],
      clgPrice: "₹1,66,000",
      clgInfoDownload: [<AiOutlineCloudDownload />, "Download Propectus"],
      clgCompare: "Add to compare",
      clgMore: "More",
      clgCourse: "UGC, AICTE, NIRF, WES, NAAC, A+",
      id: "pacific-university",
    },
    {
      category: "Marketing Management",
      clgImg: marwadigate,
      clgHeading: "Marwadi University",
      clgRateing: [
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiOutlineStar className="text-yellow-400" />,
      ],
      clgListen: [<AiFillAudio />, "Listen Podcast"],
      clgVideo: [<AiOutlineVideoCamera />, "Watch Video"],
      clgPrice: "₹1,66,000",
      clgInfoDownload: [<AiOutlineCloudDownload />, "Download Propectus"],
      clgCompare: "Add to compare",
      clgMore: "More",
      clgCourse: "UGC, AICTE, NIRF, WES, NAAC, A+",
      id: "marwadi-university",
    },
    {
      category: "Finance Management",
      clgImg: karnawatigate,
      clgHeading: "Karnavati University",
      clgRateing: [
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiOutlineStar className="text-yellow-400" />,
      ],
      clgListen: [<AiFillAudio />, "Listen Podcast"],
      clgVideo: [<AiOutlineVideoCamera />, "Watch Video"],
      clgPrice: "₹1,66,000",
      clgInfoDownload: [<AiOutlineCloudDownload />, "Download Propectus"],
      clgCompare: "Add to compare",
      clgMore: "More",
      clgCourse: "UGC, AICTE, NIRF, WES, NAAC, A+",
      id: "karnavati-university",
    },
    {
      category: "Finance Management",
      clgImg: raiahemdabadgate,
      clgHeading: "Rai University Ahemdabad",
      clgRateing: [
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiOutlineStar className="text-yellow-400" />,
      ],
      clgListen: [<AiFillAudio />, "Listen Podcast"],
      clgVideo: [<AiOutlineVideoCamera />, "Watch Video"],
      clgPrice: "₹1,66,000",
      clgInfoDownload: [<AiOutlineCloudDownload />, "Download Propectus"],
      clgCompare: "Add to compare",
      clgMore: "More",
      clgCourse: "UGC, AICTE, NIRF, WES, NAAC, A+",
      id: "rai-university-ahemdabad",
    },
    {
      category: "Data Science",
      clgImg: raibengloregate,
      clgHeading: "Rai University Benglore",
      clgRateing: [
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiOutlineStar className="text-yellow-400" />,
      ],
      clgListen: [<AiFillAudio />, "Listen Podcast"],
      clgVideo: [<AiOutlineVideoCamera />, "Watch Video"],
      clgPrice: "₹1,66,000",
      clgInfoDownload: [<AiOutlineCloudDownload />, "Download Propectus"],
      clgCompare: "Add to compare",
      clgMore: "More",
      clgCourse: "UGC, AICTE, NIRF, WES, NAAC, A+",
      id: "rai-university-benglore",
    },
    {
      category: "Operations Management",
      clgImg: spsugate,
      clgHeading: "Sir Padampat Singhania University",
      clgRateing: [
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiOutlineStar className="text-yellow-400" />,
      ],
      clgListen: [<AiFillAudio />, "Listen Podcast"],
      clgVideo: [<AiOutlineVideoCamera />, "Watch Video"],
      clgPrice: "₹1,66,000",
      clgInfoDownload: [<AiOutlineCloudDownload />, "Download Propectus"],
      clgCompare: "Add to compare",
      clgMore: "More",
      clgCourse: "UGC, AICTE, NIRF, WES, NAAC, A+",
      id: "spsu",
    },
    {
      category: "Operations Management",
      clgImg: haridwargate,
      clgHeading: "Haridwar University",
      clgRateing: [
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiOutlineStar className="text-yellow-400" />,
      ],
      clgListen: [<AiFillAudio />, "Listen Podcast"],
      clgVideo: [<AiOutlineVideoCamera />, "Watch Video"],
      clgPrice: "₹1,66,000",
      clgInfoDownload: [<AiOutlineCloudDownload />, "Download Propectus"],
      clgCompare: "Add to compare",
      clgMore: "More",
      clgCourse: "UGC, AICTE, NIRF, WES, NAAC, A+",
      id: "haridwar-university",
    },
    {
      category: "Operations Management",
      clgImg: knmodigate,
      clgHeading: "Dr. K.N. Modi University",
      clgRateing: [
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiOutlineStar className="text-yellow-400" />,
      ],
      clgListen: [<AiFillAudio />, "Listen Podcast"],
      clgVideo: [<AiOutlineVideoCamera />, "Watch Video"],
      clgPrice: "₹1,66,000",
      clgInfoDownload: [<AiOutlineCloudDownload />, "Download Propectus"],
      clgCompare: "Add to compare",
      clgMore: "More",
      clgCourse: "UGC, AICTE, NIRF, WES, NAAC, A+",
      id: "knmodi-university",
    },
    {
      category: "Retail Management",
      clgImg: iconindoregate,
      clgHeading: "Icon Universiy Indore",
      clgRateing: [
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiFillStar className="text-yellow-400" />,
        <AiOutlineStar className="text-yellow-400" />,
      ],
      clgListen: [<AiFillAudio />, "Listen Podcast"],
      clgVideo: [<AiOutlineVideoCamera />, "Watch Video"],
      clgPrice: "₹1,66,000",
      clgInfoDownload: [<AiOutlineCloudDownload />, "Download Propectus"],
      clgCompare: "Add to compare",
      clgMore: "More",
      clgCourse: "UGC, AICTE, NIRF, WES, NAAC, A+",
      id: "icon-indore",
    },
  ]);
  const [viewAll, SetViewAll] = useState([]);
  const [changeNum, SetChangeNum] = useState(6);
  function showAll() {
    SetChangeNum(changeNum + 3);
  }
  useEffect(() => {
    SetViewAll(bestCollege);
  }, []);
  return (
    <div className="w-full py-10 px-4 lg:px-0">
      <div className="container mx-auto">
        <div className="flex justify-between flex-wrap">
          {/* Fillter Section Start */}
          <div className="w-full md:w-[23%]  self-start">
            <h1 className="text-center text-2xl font-semibold">
              Search By Fillter
            </h1>
            <div className="bg-white shadow-xl px-3 py-5 my-4">
              <div className="flex py-5 justify-between items-center">
                <h3 className="text-md font-bold">Specialisations</h3>
                <AiOutlineSearch className="text-xl" />
              </div>
              <div className="h-[500px]  overflow-y-scroll ">
                <form>
                  <div className="flex items-center mb-4 customeinput">
                    <input
                      onClick={allbtn}
                      type="radio"
                      name="countries"
                      value="All"
                      className="w-4 h-4 border-gray-300  dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Not Decided yet
                    </label>
                  </div>

                  <div className="flex items-center mb-4 customeinput">
                    <input
                      onClick={(e) => finacebtn(e.target.value)}
                      type="radio"
                      name="countries"
                      value="Finance Management"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Finance Management
                    </label>
                  </div>

                  <div className="flex items-center mb-4 customeinput">
                    <input
                      onClick={(e) => finacebtn(e.target.value)}
                      type="radio"
                      name="countries"
                      value="Marketing Management"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Marketing Management
                    </label>
                  </div>
                  <div className="flex items-center mb-4 customeinput">
                    <input
                      onClick={(e) => finacebtn(e.target.value)}
                      type="radio"
                      name="countries"
                      value="HR Management"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      HR Management
                    </label>
                  </div>
                  <div className="flex items-center mb-4 customeinput">
                    <input
                      onClick={(e) => finacebtn(e.target.value)}
                      type="radio"
                      name="countries"
                      value="Operations Management"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Operations Management
                    </label>
                  </div>

                  <div className="flex items-center mb-4 customeinput">
                    <input
                      type="radio"
                      name="countries"
                      value="USA"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      IT Management
                    </label>
                  </div>

                  <div className="flex items-center mb-4 customeinput">
                    <input
                      type="radio"
                      name="countries"
                      value="USA"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Business Analytics{" "}
                    </label>
                  </div>

                  <div className="flex items-center mb-4 customeinput">
                    <input
                      type="radio"
                      name="countries"
                      value="USA"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      International Business Management
                    </label>
                  </div>

                  <div className="flex items-center mb-4 customeinput">
                    <input
                      type="radio"
                      name="countries"
                      value="USA"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Logistics And Supply Chain (Dual)
                    </label>
                  </div>

                  <div className="flex items-center mb-4 customeinput">
                    <input
                      type="radio"
                      name="countries"
                      value="USA"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Data Science
                    </label>
                  </div>

                  <div className="flex items-center mb-4 customeinput">
                    <input
                      type="radio"
                      name="countries"
                      value="USA"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Data Science and Analytics (Dual)
                    </label>
                  </div>

                  <div className="flex items-center mb-4 customeinput">
                    <input
                      type="radio"
                      name="countries"
                      value="USA"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Healthcare Management
                    </label>
                  </div>

                  <div className="flex items-center mb-4 customeinput">
                    <input
                      type="radio"
                      name="countries"
                      value="USA"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Project Management
                    </label>
                  </div>

                  <div className="flex items-center mb-4 customeinput">
                    <input
                      type="radio"
                      name="countries"
                      value="USA"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Retail Management
                    </label>
                  </div>

                  <div className="flex items-center mb-4 customeinput">
                    <input
                      type="radio"
                      name="countries"
                      value="USA"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Entrepreneurship and Leadership (Dual){" "}
                    </label>
                  </div>

                  <div className="flex items-center mb-4 customeinput">
                    <input
                      type="radio"
                      name="countries"
                      value="USA"
                      className="w-4 h-4 border-gray-300  focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Financial Markets Management
                    </label>
                  </div>
                </form>
              </div>
            </div>

            {/* Price Fillter Section Start */}
            <div className="bg-white shadow-xl px-4 py-5 my-4">
              <h3 className="text-md font-bold">Budget</h3>
              <p className="py-2 text-orange-600">₹40,000 - 13,30,000</p>
              <input
                type="range"
                min="40,000"
                max="13,30,000"
                className="test w-full"
              />
            </div>
            {/* End Price Fillter Sectio */}
          </div>
          {/* End Fillter Section */}

          {/* Side By All Best College Start*/}
          <div className="w-full md:w-[75%]">
            <div className="flex gap-[1%] flex-wrap">
              {viewAll.slice(0, changeNum).map((elem, inde) => {
                return (
                  <Link
                    to={`/university-page/${elem.id}`}
                    className="w-full md:w-[48%] lg:w-[32.33%] mb-5"
                    key={inde}
                  >
                    <div className="w-full mb-5" key={inde}>
                      <img src={elem.clgImg} className="max-w-full mx-auto" />
                      <div className="text bg-[#fffff] shadow-lg px-2 py-3">
                        <a
                          target="_blank"
                          className="text-xl font-bold pb-3"
                          href="manipal-university"
                        >
                          {elem.clgHeading}
                        </a>
                        {console.log(elem.clgHeading)}

                        <span className="flex color_Chocie pb-3">
                          {elem.clgRateing}
                        </span>

                        {/* Start Listen And Watch Video */}
                        <div className="flex justify-between">
                          <div>
                            <h5 className="flex gap-1 items-center">
                              {elem.clgListen}
                            </h5>
                          </div>
                          <div>
                            <h5 className="flex items-center gap-1">
                              {elem.clgVideo}
                            </h5>
                          </div>
                        </div>
                        {/* EnD Listen And Watch Video */}

                        {/* Price ANd Download Section Start */}
                        <div className="flex justify-between py-2 items-center">
                          <span className="text-blue-500 font-semibold">
                            {elem.clgPrice}
                          </span>
                          <div>
                            <span className="flex items-center gap-1 bg-green-200 text-green-700 text-sm px-2">
                              {elem.clgInfoDownload}
                            </span>
                          </div>
                        </div>
                        {/* EnD Price And Download Section */}

                        <h3 className="mb-3">{elem.clgCourse}</h3>

                        <div className="flex justify-between py-3 border-t">
                          <h5>{elem.clgCompare}</h5>
                          <h5>{elem.clgMore}</h5>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
            {changeNum == bestCollege.length || viewAll.length <= 6 ? (
              ""
            ) : (
              <button
                type="button"
                onClick={showAll}
                className="text-white block mx-auto bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                View All
              </button>
            )}
          </div>
        </div>
        {/* End Side By ALl Best College */}
      </div>
    </div>
  );
}

export default AllUniversity;

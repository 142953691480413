import React from "react";
import Container from "../container/Container";

const Admission = () => {
  return (
    <Container fluid extraclassName="bg-[#FFF] py-10">
      <Container>
        <h1 className="text-center py-8 text-[35px] font-semibold">
          Admission Process
        </h1>
        <img
          src="images/process.png"
          alt=""
          className="mx-auto lg:block hidden shadow-2xl"
        />
        <img
          src="images/admissionprocess.png"
          alt=""
          className="mx-auto lg:hidden block"
        />
      </Container>
    </Container>
  );
};

export default Admission;

import React from "react";
import { IoIosPeople } from "react-icons/io";

function WhatMakeUs() {
  return (
    <div className="w-full py-16 bg-[#F8FAFE]">
      <div className="container mx-auto">
      <div className="flex  md:flex-nowrap flex-wrap bg-icon">
      <div className="w-[100%] md:w-[40%] self-center">
  
        <h2 className="md:!text-left text-center text-5xl mb-3 md:mb-0 leading-[60px]">
          What <span className="text-blue-700  hover:border-b-2">Makes</span> <br />
          Us Stand <span>Apart</span>
        </h2>
      </div>
      <div className="w-[100%] md:w-[60%] flex justify-between flex-wrap">
        <div className="w-[100%] md:w-[50%] px-2 mb-8">
          <div className=" rounded-xl relative shadow border-l-[2px] border-l-red-600">
            <div className="absolute text-5xl top-[-12%] left-1 mb-5">
              <IoIosPeople className="text-red-600" />
            </div>
            <div className="p-[1.5rem] sm:!p-[3rem]">
              <p className="font-bold">5 Million +</p>
              <p>Students Trust College Vidya for Unbiased Counselling</p>
            </div>
          </div>
        </div>
        <div className="w-[100%] md:w-[50%] px-2 mb-8">
          <div className="border-l-[2px] border-l-green-600 shadow rounded-xl relative">
            <div className="absolute text-5xl top-[-12%] left-1">
              <IoIosPeople className="text-green-600" />
            </div>
            <div className="p-[1.5rem] sm:!p-[3rem]">
              <p className="font-bold">5 Million +</p>
              <p>Students Trust College Vidya for Unbiased Counselling</p>
            </div>
          </div>
        </div>

        <div className="w-[100%] md:w-[50%] px-2 mb-8">
          <div className="border-l-[2px] border-l-purple-600 shadow rounded-xl relative">
            <div className="absolute text-5xl top-[-12%] left-1">
              <IoIosPeople className="text-purple-600" />
            </div>
            <div className="p-[1.5rem] sm:!p-[3rem]">
              <p className="font-bold">5 Million +</p>
              <p>Students Trust College Vidya for Unbiased Counselling</p>
            </div>
          </div>
        </div>

        <div className="w-[100%] md:w-[50%] px-2 mb-8">
          <div className="border-l-[2px] border-l-blue-600 shadow rounded-xl relative">
            <div className="absolute text-5xl top-[-12%] left-1">
              <IoIosPeople className="text-blue-600" />
            </div>
            <div className="p-[1.5rem] sm:!p-[3rem]">
              <p className="font-bold">5 Million +</p>
              <p>Students Trust College Vidya for Unbiased Counselling</p>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
   
    </div>
  );
}

export default WhatMakeUs;

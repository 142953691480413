import React from "react";
import ourTeamA from "./img/our-team-A.jpeg"
import ourTeamB from "./img/our-team-B.jpeg"
import ourTeamC from "./img/our-team-C.jpeg"
import ourTeamD from "./img/our-team-D.jpeg"

function OurTeam() {
    return (
        <div className="w-full py-16 bg-[#F8FAFE]">
            <div className="container mx-auto">
            <div className="text-center mb-4">
            <h1 className="text-2xl">Our Perfect Team</h1>
            <p className="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, nulla!</p>
            <button className="bg-yellow-500 py-2 px-8 rounded text-white">Call Now</button>
            </div>
            <div className="flex flex-wrap gap-[2%]">
              <div className="md:w-[48%] w-[100%] flex gap-5 mb-3">
                <div className="w-[35%] self-center md:self-stretch">
                    <img src={ourTeamD} className="rounded-full object-none object-[52%_16%]" alt="" />
                </div>
                <div className="w-[40%]">
                    <h4 className="">Jeffery Brwon</h4>
                    <p>Senior Teacher</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi, deserunt?</p>
                </div>
              </div>
              <div className="md:w-[48%] w-[100%] flex gap-5 mb-3">
                <div className="w-[35%] self-center md:self-stretch">
                    <div className="rounded-[50%]" >
                    <img src={ourTeamB}  className="rounded-full object-none object-[52%_16%]" alt="" />
                    </div>
                </div>
                <div className="w-[40%]">
                    <h3>Jeffery Brwon</h3>
                    <p>Senior Teacher</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi, deserunt?</p>
                </div>
              </div>
              <div className="md:w-[48%] w-[100%] flex gap-5 mb-3">
                <div className="w-[35%] self-center md:self-stretch">
                    <img src={ourTeamC} className="rounded-full object-none object-[52%_16%]" alt="" />
                </div>
                <div className="w-[40%]">
                    <h3>Jeffery Brwon</h3>
                    <p>Senior Teacher</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi, deserunt?</p>
                </div>
              </div>
              <div className="md:w-[48%] w-[100%] flex gap-5 mb-3">
                <div className="w-[35%] self-center md:self-stretch">
                    <img src={ourTeamD} className="rounded-full object-none object-[52%_16%]" alt="" />
                </div>
                <div className="w-[40%]">
                    <h3>Jeffery Brwon</h3>
                    <p>Senior Teacher</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi, deserunt?</p>
                </div>
              </div>
            </div>
        </div>
        </div>
    );
}

export default OurTeam;

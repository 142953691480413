
import React, { useState } from "react";
import "./index.css";
import { TbArrowNarrowUp, TbRotateRectangle } from "react-icons/tb";
import { FcCustomerSupport, FcTodoList } from "react-icons/fc";


function OneStopSolution() {
  const [solution, setSolution] = useState([
    {
      ico: <TbArrowNarrowUp className="text-4xl font-[900] text-white" />,
      titel: "Select Your Desired course",
      subTit: "Get one step closer to your dream career with the right course",
    },
    {
      ico: <TbRotateRectangle className="text-4xl font-[900] text-white" />,
      titel: "Fill In the Basic Details",
      subTit: "Help us know you better with basic information & few questions",
    },
    {
      ico: <FcTodoList className="text-4xl font-[900] text-white" />,
      titel: "Explore Your Options",
      subTit:
        "Don't limit yourself, explore top universities with our specially designed list",
    },
    {
      ico: <TbRotateRectangle className="text-4xl font-[900] text-white" />,
      titel: "Get the Best",
      subTit:
        "Compare top universities on different aspects like Student rating, CV score, Placement assistance etc",
    },
    {
      ico: <FcCustomerSupport className="text-4xl font-[900] text-white" />,
      titel: "With You, For You",
      subTit: "Call our team of experts for all your queries and questions.",
    },
    {
      ico: <FcTodoList className="text-4xl font-[900] text-white" />,
      titel: "Business Studies",
      subTit: "Call our team of experts for all your queries and questions.",
    },
  ]);
  return (
    <div className="bg-[#f2f2f2] w-full py-[5rem]   flex flex-col items-center ">
      <div className="w-fit flex justify-center mb-8">
        <p className="text-[30px] font-bold">
          Our One Stop Solution
        </p>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-wrap gap-3">
          {solution.map((data, i) => {
            return (
              <div className="w-full sm:w-[48%] lg:w-[30%] mb-5 px-2">
                <div className=" flex border-4 gap-3 p-2 min-h-[170px]
                rounded-xl
                 items-center hover:shadow-2xl">
                  <div className="flex w-[60px] h-[60px] rounded-full
                   bg-blue-400 items-center text-white justify-center">
                    {data.ico}
                  </div>
                  <div className="w-[80%] ">
                    <h3 className="bg-yellow-500 text-white pl-2 rounded-md">{data.titel}</h3>
                    <p className="">{data.subTit}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OneStopSolution;

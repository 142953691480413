
import React from 'react';
import service9 from "./image/service-9.webp"
import service10 from "./image/service-10.webp"
import service11 from "./image/service-11.webp"
import service12 from "./image/service-12.webp"
import manImage from "./image/menimgabout.jpg"
// import service10 from "./image/service-10.webp"

const AboutBanner = () => {
    return (
        <div className="w-[100%] py-5 ">
            <div  className="flex lg:w-[90%] mx-auto lg:flex-row flex-col justify-between  sm:py-1">
                <div className='lg:w-[60%] w-[100%] items-center  py-[20px] sm:py-[90px] order-2 lg:order-1 flex flex-col gap-0 sm:gap-24'>
                    <div className='sm:flex justify-between px-5'>
                        <div className='flex sm:w-[49%] sm:py-0 py-5 w-[99%] mx-auto gap-6'>
                            <img
                                className='w-[60px] h-[60px]'
                                width={10}
                                height={10}
                                alt='image not Found'
                                src={service9}
                                sizes='10vw'
                            />
                            <div>
                                <div className='text-[#48a7d4] font-[700] text-[18px]'>Scholarship AboutMiddle</div>
                                <div className='text-[13px]'>magna aliqua. Ut enim ad minim veniam conse ctetur adipisicing elit, sed do exercitation ullamco</div>
                            </div>
                        </div>
                        <div className='flex sm:w-[49%] sm:py-0 py-5 w-[99%] mx-auto gap-6'>
                            <img
                                className='w-[60px] h-[60px]'
                                width={10}
                                height={10}
                                alt='img not Fount'
                                src={service10}
                                sizes='10vw'
                            />
                            <div>
                                <div className='text-[#fdc735] font-[700] text-[18px]'>Best Teacher</div>
                                <div className='text-[13px]'>
                                    magna aliqua. Ut enim ad minim veniam conse ctetur adipisicing elit, sed do exercitation ullamco
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sm:flex  justify-between px-5'>
                        <div className='flex sm:w-[49%] sm:py-0 py-5 w-[99%] mx-auto gap-6'>
                            <img
                                className='w-[60px] h-[60px]'
                                width={10}
                                height={10}
                                alt='img not Fount'
                                src={{service11}}
                                sizes='10vw'
                            />
                            <div>
                                <div className='text-[#48a7d4] font-[700] text-[18px]'>Library & Book Store</div>
                                <div className='text-[13px]'>magna aliqua. Ut enim ad minim veniam conse ctetur adipisicing elit, sed do exercitation ullamco</div>
                            </div>
                        </div>
                        <div className='flex sm:w-[49%] sm:py-0 py-5 w-[99%] mx-auto gap-6'>
                            <img
                                className='w-[60px] h-[60px]'
                                width={10}
                                height={10}
                                alt='img not Fount'
                                src={service12}
                                sizes='10vw'
                            />
                            <div>
                                <div className='text-[#00a651] font-[700] text-[18px]'>25 Years Of Experience</div>
                                <div className='text-[13px]'>
                                    magna aliqua. Ut enim ad minim veniam conse ctetur adipisicing elit, sed do exercitation ullamco
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='lg:w-[28%] mt-6 lg:mt-0 order-1 w-[80%] px-8 sm:px-0 lg:order-2 sm:w-[60%] sm:mx-0 mx-auto'>
                    <img
                        className='w-full h-full'
                        width={10}
                        height={10}
                        alt='img not Fount'
                        src={manImage}
                        sizes='50vw'
                    />
                </div>
                
            </div>
        </div>
    );
}

export default AboutBanner;


import React, { useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

 import "swiper/css";

import "./index.css"

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import homebanner1 from "./image/h1.jpg"
import homebanner2 from "./image/h2.jpg"
import homebanner3 from "./image/h3.jpg"
import homebanner4 from "./image/h4.jpg"


const   HomeBanner = () => {
  return (
    <>
    <div className="flex relative z-[-1] h-[15rem] sm:h-[25rem]">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination]}
        className="mySwiper">
        <SwiperSlide>
          <div className="w-full h-[25rem] ">
          <img
            className=" w-full h-full bg-[#190028] object-fill  object-center"
            src={homebanner1}
            alt="image not found"
            height={0}
            sizes="100vw"
          />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full h-[25rem]">
          <img
            className=" w-full h-full bg-[#190028] object-fill  object-center"
            src={homebanner2}
            alt="image not found"
            height={0}
            width={0}
            sizes="100vw"
          />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full h-[25rem]">
          <img
            className=" w-full h-full bg-[#190028] object-fill  object-center"
            src={homebanner3}
            alt="image not found"
            height={0}
            width={0}
            sizes="100vw"
          />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full h-[25rem]">
          <img
            className=" w-full h-full bg-[#190028] object-fill object-center"
            src={homebanner4}
            alt="image not found"
            height={0}
            width={0}
            sizes="100vw"
          />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="w-full h-[25rem]">
          <img
            className=" w-full h-full bg-[#190028] object-fill object-center"
            src={homebanner1}
            alt="image not found"
            height={0}
            width={0}
            sizes="100vw"
          />
          </div>
        </SwiperSlide>
        
      </Swiper>
      </div>
    </>
  );
};

export default HomeBanner;

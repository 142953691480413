import React from "react";
import Container from "../container/Container";
import InfiAnimation from "../../extra/infiAnimation";

const HiringPartner = ({ uni }) => {
  return (
    <Container>
      <h1 className="text-center py-4 text-[35px] font-semibold capitalize">
        Our learner work at
      </h1>
      <h4 className="text-center pb-10 text-[20px] font-semibold capitalize">
        Top hiring partners of {uni ? uni.university : "Internal Server Error"}
      </h4>
      {/* <h1 className='text-center text-[20px]'>Slider</h1> */}
      <InfiAnimation uni={uni} />
    </Container>
  );
};

export default HiringPartner;

import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../home/index";
import About from "../about/index";
import Contact from "../contact";
import AllUniversity from "../../components/common/allUniversity";
import UniversityPage from "../../components/university-page";

const Root = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/allUniversity" element={<AllUniversity />} />
      <Route path="/university-page/:id" element={<UniversityPage />} />
    </Routes>
  );
};

export default Root;

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css"

const CourseType = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    // Additional initialization or handling can be done 
  }, []); // Empty dependency array to run the effect once on component mount

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Adjust the number of slides to show based on your design
    slidesToScroll: 1,
  };

  return (
    <div className="w-full bg-white py-5 md:py-16 md:pb-16 ">
         <div className="container mx-auto flex flex-col items-center ">
    <div className="w-full md:w-[94%] shadow-2xl mb-5 ">
      <Slider {...settings} className="slider">
        {/* Mapping through your course data */}
        {[0, 1, 2, 3, 4, 5].map((courseIndex) => (
          <li
            key={courseIndex}
            onClick={() => setSelectedCourse(courseIndex)}
            className={`bg-${selectedCourse === courseIndex ? "[#0000FF]" : "#002147"}  pt-4 font-bold md:text-xl sm:text-sm rounded-lg py-2 px-3 text-#0000FF hover:text-[#fdc800]`}
          >
            Course {courseIndex + 1}
          </li>
        ))}
      </Slider>
    </div>
    </div>
    </div>
  );
};

export default CourseType;


// import React, { useState } from "react";
// import UniversityDiv from "../../../components/common/universityDiv/page";

// const CourseType = () => {
//   const [selectedCourse, setSelectedCourse] = useState(null);

//   return (
//     <div className="w-full bg-white py-5 md:py-16 md:pb-16 ">
//       <div className="container mx-auto flex flex-col items-center ">
//         <div className="w-full md:w-[94%] shadow-2xl mb-5 ">
//           <ul className="pb-[2.5rem] pt-[1rem] flex justify-between gap-4 p-4">
//             <li
//               onClick={() => setSelectedCourse(0)}
//               className= {` bg-${selectedCourse === 0 ? "[#0000FF]" : "#002147"} pt-4 font-bold md:text-xl sm:text-sm rounded-lg py-2 px-3 text-#0000FF hover:text-[#fdc800]`}
//             >
//               Pg Courses
//             </li>
//             <li
//               onClick={() => setSelectedCourse(1)}
//               className={`bg-${selectedCourse === 1 ? "[#0000FF]" : ""} pt-4 pl-2 text-xl rounded-lg py-2 px-3 text-#0000FF hover:text-[#fdc800]`}
//             >
//               Ug Courses
//             </li>
//             <li
//               onClick={() => setSelectedCourse(2)}
//               className={`bg-${selectedCourse === 2 ? "[#0000FF]" : ""} pt-4 pl-2 text-xl rounded-lg py-2 px-3 text-#0000FF hover:text-[#fdc800]`}
//             >
//               Executive Education
//             </li>
//             <li
//               onClick={() => setSelectedCourse(3)}
//               className={`bg-${selectedCourse === 3 ? "[#0000FF]" : ""} pt-4 pl-2 text-xl rounded-lg py-2 px-3 text-#0000FF hover:text-[#fdc800]`}
//             >
//               Job Guarantee
//             </li>
//             <li
//               onClick={() => setSelectedCourse(4)}
//               className={`bg-${selectedCourse === 4 ? "[#0000FF]" : ""} pt-4 pl-2 text-xl rounded-lg py-2 px-3 text-#0000FF hover:text-[#fdc800]`}
//             >
//               Skilling & Certificate
//             </li>
//             <li
//               onClick={() => setSelectedCourse(5)}
//               className={`bg-${selectedCourse === 5 ? "[#0000FF]" : ""} pt-4 pl-2 text-xl rounded-lg py-2 px-3 text-#0000FF hover:text-[#fdc800]`}
//             >
//               Advance Diploma
//             </li>
//           </ul>
//         </div>
//         <div className="w-full md:w-[100%]  flex justify-center items-center">
//           <UniversityDiv />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CourseType;

import React, { useState } from "react";
import image1 from "./image/inner_b1.jpg";
import image2 from "./image/inner_b2.jpg";
import image3 from "./image/inner_b3.jpg";
import { FaGraduationCap, FaArrowRight, FaCalendarAlt } from "react-icons/fa";

function OurBlog({ uni }) {
 
  return (
    <div className="w-full py-12 px-4 lg:px-0 bg-[#f2f2f2]">
      <div className="container mx-auto">
        <div className="text-center py-3">
          <p className="font-bold text-md flex gap-1 items-center justify-center text-red-400 mb-0">
            <FaGraduationCap />
            Our Blog
          </p>
          <h2 className="font-bold text-2xl">Latest Blog & News</h2>
        </div>
        <div className="flex justify-between flex-wrap">
          {uni && uni.blogNews.map((data, index) => {
            return (
              <div
                key={index}
                className="w-full md:w-[48%] lg:w-[30%] mb-4 group"
              >
                <div className="overflow-hidden relative ">
                  <img
                    src={data.img}
                    alt={data.alt}
                    className="group-hover:scale-125  transition duration-1000"
                  />
                  <div className="absolute top-[5%] z-10 left-6 bg-[#ff7350] p-1 px-2  text-white">
                    <div className="flex gap-1 items-center">
                      <p className="mb-0">{data.icon}</p>
                      <p className="font-thin mb-0 text-sm">{data.month}</p>
                    </div>
                  </div>
                </div>
                <div className="relative">
                  <div className="p-4 bg-[#f0f7ff]">
                    <h5 className="hover:text-red-400">{data.hed}</h5>
                    <p className="font-thin">{data.pre}</p>
                    <div className="flex items-center gap-2 text-sm hover:text-red-400">
                      <p className="text-sm">{data.txt}</p>
                      <p>{data.arrow}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OurBlog;

import React, { useContext, useEffect, useState } from "react";
import "./index.css";
// import image from "./image/Quantum_Gallery_2017_08.jpg"
import { FaBookReader, FaGraduationCap } from "react-icons/fa";
import { AiOutlineGlobal } from "react-icons/ai";
import { PiStudentBold } from "react-icons/pi";
import MotionText from "../../extra/motionText";
import AnimatedText from "../../extra/animation-charctor";

function UniversityBanner({ uni }) {
  if (!uni) {
    return null; // Return null or any other placeholder if uni is null or undefined
  }

  return (
    <div
      style={{ backgroundImage: `url(${uni.image})` }}
      className={`w-full md:px-0  bg-section]`}
    >
      <div className="w-full h-full bg-opacity-50 bg-black px-5">
        <div className="container mx-auto py-[100px]">
          <div className="relative">
            <MotionText/>
            {/* <h3 className='text-white font-bold text-3xl py-2'>World Leading University</h3>
                        <h5 className='text-white font-semibold text-xl py-1'>Quantum University in Rajasthan</h5> */}
            <div className="text-white flex gap-3 flex-wrap py-2">
              {uni &&
                uni.courses.map((course, index) => (
                  <p key={index} className="border uppercase px-2 mb-1">
                    {course.name}
                  </p>
                ))}
            </div>
            <div className="flex  flex-wrap">
              <button
                type="button"
                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-[0.5rem] md:px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
              >
                <a className="text-white no-underline" href="tel:+911234567890">
                  Call now
                </a>
              </button>

              <button
                type="button"
                className="focus:outline-none text-white bg-green-500 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-[0.5rem] md:px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
              >
                <a className="text-white no-underline" href="tel:+911234567890">
                  Chat now
                </a>
              </button>
            </div>
            <div className="static  lg:absolute py-4 lg:py-0 w-[100%] lg:w-[80%] left-[10%] right-[10%]   lg:bottom-[-55%]">
              <div className="flex justify-between flex-wrap  ">
                <div className="bg-blue-500 py-5 px-5 text-center w-[100%] md:w-[48%] lg:w-[22%] mb-3 lg:mb-0 ">
                  <FaBookReader className="text-red-400 mx-auto text-2xl" />
                  <h5 className="text-white text-sm my-2">
                    Campus Diversity Hub
                  </h5>
                </div>
                {/* items start */}
                <div className="bg-blue-500 py-3 px-5 text-center w-[100%] md:w-[48%] lg:w-[22%] mb-3 lg:mb-0">
                  <AiOutlineGlobal className="text-red-400 mx-auto text-2xl" />
                  <h5 className="text-white text-sm my-2">
                    Cutting-Edge Facilities
                  </h5>
                </div>
                {/* items end */}
                {/* items start */}
                <div className="bg-blue-500 py-3 px-5 text-center w-[100%] md:w-[48%] lg:w-[22%] mb-3 lg:mb-0">
                  <PiStudentBold className="text-red-400 mx-auto text-2xl" />
                  <h5 className="text-white text-sm my-2">
                    Scholarly Pursuits
                  </h5>
                </div>
                {/* items end */}
                {/* items start */}
                <div className="bg-blue-500 py-3 px-5 text-center w-[100%] md:w-[48%] lg:w-[22%] mb-3 lg:mb-0">
                  <FaGraduationCap className="text-red-400 mx-auto text-2xl" />
                  <h5 className="text-white text-sm my-2">Career Catalyst</h5>
                </div>
                {/* items end */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UniversityBanner;

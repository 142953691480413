import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Universitycontext from "./components/context/universitycontext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Universitycontext>
      <App />
    </Universitycontext>
  </React.StrictMode>
);

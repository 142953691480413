import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import AnimatedText from "../animation-charctor";
import { Context } from "../../context/universitycontext";
import { useParams } from "react-router-dom";

const MotionText = () => {
  const [uni, setUni] = useState(null);
  const universities = useContext(Context);
  const { id } = useParams();

  useEffect(() => {
    const foundUni = universities.find((d) => d.id === id);
    if (foundUni) {
      setUni(foundUni);
    }
  }, [id, universities]);

  useEffect(() => {
    const interval = setInterval(() => {
      const show = document.querySelector("span[data-show]");
      const next =
        show.nextElementSibling || document.querySelector("span:first-child");
      const up = document.querySelector("span[data-up]");

      if (up) {
        up.removeAttribute("data-up");
      }

      show.removeAttribute("data-show");
      show.setAttribute("data-up", "");

      next.setAttribute("data-show", "");
    }, 2000);

    // Cleanup function to clear the interval on component unmount
    return () => clearInterval(interval);
  }, [id, universities]); // Empty dependency array ensures the effect runs once after the initial render

  return (
    // Your component JSX goes here
    <>
      <h2 className="texth2">
        {uni ? uni.university : ""}
        <div className="mask">
          <span data-show>a theater</span>
          <span>a gym</span>
          <span>a concert hall</span>
          <span>an arcade</span>
        </div>
      </h2>
    </>
  );
};

export default MotionText;

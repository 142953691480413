import { useState, useEffect, useContext } from "react";
import LoanFec from "./LoanFec/LoanFec";
import TopBar from "./TopBar/TopBar";
import OurFeatures from "./OurFeatures/OurFeatures";
import OurEvent from "./OurEvent/OurEvent";
import OurBlog from "./OurBlog/OurBlog";
import SearchCourses from "./SearchCourses/SearchCourses";
import Testimonals from "./Testimonals/Testimonals";
// import Footer from "./Footer/Footer";
import Scholarships from "./Scholarships/Scholarships";
import UniversityBanner from "./university-banner";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Courses from "./courses";
import Admission from "./admission/Admission";
import ConnetQuery from "./contact-query/ConnetQuery";
import HiringPartner from "./hiring-partner/HiringPartner";
import ConnectWithUs from "./connect-withus";
import OurConnection from "./ourconnection";
import YourComponent from "../extra/aboutGraph";
import Brands from "./brands";
import InfiAnimation from "../extra/infiAnimation";
import NewsSlider from "../../screens/home/newsSlider";
import UniversityFacilities from "./facilities";
import UniContact from "./uniContact";
import OfficeAddress from "./officeAddress";
import UniFaq from "./uniFaq";
import { Context } from "../context/universitycontext";
import { useParams } from "react-router-dom";
// import InfiniteAnimation from "../extra/infiniteAnimation";

function UniversityPage() {
  const [uni, setUni] = useState(null);
  const universities = useContext(Context);
  const { id } = useParams();
  useEffect(() => {
    const foundUni = universities.find((d) => d.id === id);
    if (foundUni) {
      setUni(foundUni);
    }
  }, [id, universities]);
  console.log(id, uni);
  return (
    <div className="w-full  overflow-hidden">
      <TopBar />
      <UniversityBanner uni={uni} />
      <Courses uni={uni} />
      <OurEvent uni={uni} />
      {/* <YourComponent/> */}
      {/* <InfiniteAnimation/> */}
      <OurFeatures />
      <UniversityFacilities uni={uni} />
      <Testimonals uni={uni} />
      <Scholarships uni={uni} />
      <LoanFec uni={uni} />
      <ConnetQuery uni={uni} />
      {/* <Container/> */}
      <HiringPartner uni={uni} />
      {/* <ConnectWithUs/> */}
      <OfficeAddress uni={uni} />
      <OurConnection uni={uni} />
      {/* <Brands/> */}
      <SearchCourses />
      <NewsSlider uni={uni} />
      <UniFaq uni={uni} />
      <Admission />
      <OurBlog uni={uni} />

      {/* <UniContact/> */}
      {/* <Footer /> */}
    </div>
  );
}

export default UniversityPage;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import MainPopup from "../mainPopup/page";
import colors from "../../../utils/colour";
import HoverUniversities from "../hoverUniveristies/page";
import { FaBars } from "react-icons/fa";
import { IoLogoWhatsapp, IoMdClose } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import homeicon from "./mobicon/home.png";
import aboutIcon from "./mobicon/about.png";
import contacticon from "./mobicon/communicate.png";
import signupIcon from "./mobicon/add-friend.png";
import backIcon from "./mobicon/arrows.png";
import collegeIcon from "./mobicon/university.png";

const Navbar = () => {
  const [open, setopen] = useState(false);
  const [mobilnav, setMobilnav] = useState(false);
  const [close, setClose] = useState(false);
  const [menuopen, setmenuOpen] = useState(false);
  const PopUpOpen = () => {
    setopen(true);
  };

  const toggleMenu = () => {
    setmenuOpen(!menuopen);
    alert("bhawani");
  };

  return (
    <>
      <div
        style={{
          color: colors.textBlack,
        }}
        className="w-full  shadow-lg py-[5px]">
        <div className="mx-auto lg:w-[90%] px-3 lg:px-0  flex gap-9 xl:gap-2 items-center py-2 md:py-4 xl:justify-between relative">
          <div className="flex  lg:w-[40%] xl:w-[55%] justify-between lg:gap-5 xl:gap-20">
            <Link to="/" className="flex items-center">
              <h1
                className="bg-transparent lg:hover:scale-110 duration-300  font-bold text-[20px] sm:text-[28px]"
                style={{
                  color: colors.theme,
                }}>
                Aaopadhe
              </h1>
            </Link>

            <div className=" items-center xl:w-[60%] hidden border-2 rounded-md  gap-3  text-gray-500 lg:flex ">
              <AiOutlineSearch className="text-gray-500 ms-2 text-[25px] md:text-gray-500" />
              <input
                type="text"
                className=" text-[20px] bg-transparent w-[60%] xl:w-[85%] outline-none border-none "
                placeholder="Search for"
              />
            </div>
          </div>
          <ul className="xl:w-[45%] lg:flex hidden lg:w-[55%] text-[14px]  xl:text-[15px] justify-end gap-2 sm:gap-4 xl:gap-6">
            <Link
              className={`shadow-lg px-3 py-2 group  bg-[${colors.textBlack}] hover:hover:bg-[${colors.theme}] font-bold 
           hover:text-[#fdc800] duration-300 cursor-pointer  rounded-xl`}
              to="/">
              Home
            </Link>
            <Link
              className={`shadow-lg px-3 py-2 group bg-[${colors.textBlack}] hover:hover:bg-[${colors.theme}] font-bold 
           hover:text-[#fdc800] duration-300 cursor-pointer  rounded-xl`}
              to="/about">
              About
            </Link>
            <li
              className={`shadow-lg px-3 lg:block hidden py-2 group bg-[${colors.textBlack}] hover:hover:bg-[${colors.theme}] font-bold 
           hover:text-[#fdc800] duration-300 cursor-pointer  rounded-xl`}>
              Top Universities
              {/* Hover Efffect Create */}
              <div
                className={`w-full z-50 absolute left-0 top-[58px] shadow-md bg-[${colors.theme}]  text-white hidden group-hover:block`}>
                <HoverUniversities />
              </div>
              {/* EnD Hover Effect  */}
            </li>
            <Link
              className="shadow-lg cursor-pointer px-3 py-2 group bg-white  hover:hover:bg-[#002147] font-bold 
           hover:text-[#fdc800] duration-300 rounded-xl"
              to="/contact">
              Contact
            </Link>
            <li
              className="shadow-lg px-3 sm:block hidden py-2 cursor-pointer bg-white hover:hover:bg-[#002147] 
            font-bold  duration-300 hover:text-[#fdc800] rounded-xl"
              onClick={PopUpOpen}>
              Sign in
            </li>
          </ul>
          <FaBars
            className="text-[20px] absolute right-4 lg:hidden block"
            onClick={() => setMobilnav(true)}
          />
        </div>
      </div>
      {/* whatsapp & call icon screen  start*/}
      <div className="fixed bottom-0 text-[30px] right-0 gap-3 px-8 py-6 z-[9999]">
        <div className="p-3 bg-gray-300 mb-3 rounded-full">
          <a to="tel:+919988776655" target="_blank">
            <IoCall className="text-blue-500" />
          </a>
        </div>
        <div className="p-3 bg-gray-300 mb-2 rounded-full">
          <a href="https://wa.me/+919988776655" target="_blank">
            <IoLogoWhatsapp className="text-green-500" />
          </a>
        </div>
      </div>
      {/* whatsapp & call icon screen  end*/}

      {/* Mobile Menu */}

      <div
        className={`fixed block lg:hidden px-2 top-0 right-0 bg-white z-10  h-[100vh] w-full duration-700 ${
          mobilnav === true ? "left-[0%]" : "left-[-100%]"
        }`}>
        <div className="container mx-auto">
          <div className="items-center py-2 gap-3 text-gray-500 flex justify-between">
            <div className="flex gap-2">
              <AiOutlineSearch className="text-[25px] text-gray-500" />
              <input
                type="text"
                className=" text-[16px] bg-transparent w-[100%] border-b-2 outline-none "
                placeholder="Search for"
              />
            </div>
          </div>
          {/* all listing page */}
          <div>
            <ul className="flex py-3 flex-col	gap-2 px-2">
              <li className="flex gap-2">
                {" "}
                <img
                  src={homeicon}
                  alt="image not found"
                  className=" w-[30px] h-[30px] "></img>
                <Link
                  className="text-black mt-2"
                  to="/"
                  onClick={() => setMobilnav(false)}>
                  Home
                </Link>
              </li>
              <li className="flex gap-2">
                <img
                  src={aboutIcon}
                  alt="image not found"
                  className=" w-[20px] h-[20px] mt-1"></img>
                <Link
                  className="text-black mt-1"
                  to="/about"
                  onClick={() => setMobilnav(false)}>
                  About
                </Link>
              </li>
              <li className="group " onClick={() => setMobilnav(false)}>
                <li className="flex gap-2">
                <img
                  src={collegeIcon}
                  alt="image not found"
                  className=" w-[20px] h-[20px] "></img>
                Top Universities
                </li>
               
                <div className="group-hover:block hidden">
                  <HoverUniversities />
                </div>
              </li>
              <li className="flex gap-2">
                <img
                  src={contacticon}
                  alt="image not found"
                  className=" w-[20px] h-[20px] mt-1"></img>
                <Link
                  className="text-black mt-1"
                  to="/contact"
                  onClick={() => setMobilnav(false)}>
                  Contact
                </Link>
              </li>
              <li className="flex gap-2">
                <img
                  src={signupIcon}
                  alt="image not found"
                  className=" w-[20px] h-[20px] mt-[9px]"></img>
                <Link className="text-black mt-2" onClick={PopUpOpen}>
                  Sign Up
                </Link>
              </li>

              <li
                onClick={() => setMobilnav(false)}
                className="text-black flex gap-2">
                <img
                  src={backIcon}
                  alt="image not found"
                  className=" w-[20px] h-[20px] mt-[9px] "></img>
                <li className="mt-2">Back</li>
              </li>
            </ul>
          </div>
          {/* End */}
        </div>
        <div></div>
      </div>

      {/* ___------___ */}

      {open ? (
        <div className="w-full z-[9999]  h-[100vh] duration-1000 fixed top-0 left-0 bg-userdata"></div>
      ) : (
        <div className="w-full z-[9999] duration-1000 fixed top-0 left-0"></div>
      )}
      {open ? (
        <div
          className="w-full z-[99999] h-[100vh]  fixed 
          top-[0] left-0 duration-700 flex justify-center items-center ">
          <MainPopup
            open={open}
            onClose={() => {
              setopen(false);
            }}
          />
        </div>
      ) : (
        <div
          className="w-full h-[100vh] z-[99999] flex justify-center items-center  
           duration-700 fixed left-0 top-[-100%] ">
          <MainPopup
            open={open}
            onClose={() => {
              setopen(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export default Navbar;


import './App.css';

import Navbar from './components/common/navbar/page';
import Home from './screens/home';
import InfoBar from './screens/home/infobar';
import { BrowserRouter } from 'react-router-dom';
import Root from './screens/routes';
import BottomEnd from './components/common/bottomend';
import Footer2 from './components/common/Footer/Footer';


function App() {
  return (
    <div className="">
      <BrowserRouter>
      <InfoBar/>
      <Navbar/>
      <Root/>
     
    <Footer2/>
      <BottomEnd/>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useState } from "react";
import { MdGroups3 } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { FaReact } from "react-icons/fa";
import feature1 from "./image/our-featu.jpg"

function OurFeatures() {
  const [features, setFeatures] = useState([
    {
      icon: <MdGroups3 />,
      subHed: "Skilled Teachers",
      preGraph:
        "Special wedding garments are often worn, and the ceremony is sttimes followed by a wedding reception. Music, poetry, prayers, or readings from.",
    },
    {
      icon: <GiReceiveMoney />,
      subHed: "Affordable Courses",
      preGraph:
        "Special wedding garments are often worn, and the ceremony is sttimes followed by a wedding reception. Music, poetry, prayers, or readings from.",
    },
    {
      icon: <FaReact />,
      subHed: "Efficient & Flexible",
      preGraph:
        "Special wedding garments are often worn, and the ceremony is sttimes followed by a wedding reception. Music, poetry, prayers, or readings from.",
    },
  ]);
  return (
    <div className="w-full bg-[#032e3f] text-white px-3 lg:px-0 flex justify-center">
      <div className="container mx-auto">
        <div className="flex justify-between flex-wrap">
          {/* Start First Box */}
          <div className="w-[100%]  lg:w-[45%] py-3">
            <div className="py-2">
              <h1 className="text-2xl">Our Best Features</h1>
              <p className="text-sm font-thin py-3">
                Special wedding garments are often worn, and the ceremony is
                sometimes followed by a wedding reception. Music, poetry.
              </p>
            </div>
            <div>
              {features.map((data, inde) => {
                return (
                  <div className="flex gap-3 mb-2 items-start" key={inde}>
                   <div>
                        <div className="w-[60px] h-[60px] flex justify-center items-center rounded-[100%] bg-[#ff7350]">
                          <div className="text-2xl">{data.icon}</div>
                        </div>
                   </div>
                    <div>
                      <h4 className="font-bold text-xl">{data.subHed}</h4>
                      <p className="text-sm font-thin py-2">{data.preGraph}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* End First Box */}

          {/* Start Second Box */}
          <div className="w-full lg:w-1/2">
            <div>
              <img
                src={feature1}
                alt="student_image"
                className="max-w-full h-auto"
              />
            </div>
          </div>
          {/* End Second Box */}
        </div>
      </div>
    </div>
  );
}

export default OurFeatures;


import React from 'react'
import { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

const NumberReport = () => {
  const [count , setCount] = useState(false);
  return (
    <div className='w-full bg-white py-10'>
      <ScrollTrigger onEnter={() => setCount(true)} onExit={() => setCount(false)}>

        <div className='container mx-auto py-2 px-10 text-[15px]
           text-[#002147] flex justify-between gap-8  font-bold'>
          <div>
              {
                count ?
                 <div className='text-sm md:text-3xl  text-center'> <CountUp start={0} end={89000} duration={2} delay={0} />+ 
                </div>
                  : 
                  <div className='text-sm md:text-3xl  text-center'>
                  89000+
                </div>
              }
            <div className=' text-[#fdc800] sm:text-[20px]  text-[12px] text-center '>Trusted by Students</div>
          </div>
          <div>
            {
              count ?
                <div className='text-sm md:text-3xl  text-center'> <CountUp start={0} end={500} duration={2} delay={0} />+
                </div>
                :
                <div className='text-sm md:text-3xl  text-center'>
                  500+
                </div>
            }
            <div className='sm:text-[20px]  text-[12px] text-[#fdc800] text-center'>Expert Mentors</div>
          </div>
          <div>
            {
              count ?
                <div className='text-sm md:text-3xl text-center'>4.9 / <CountUp start={0}  end={537} duration={2} delay={0} />
                </div>
                :
                <div className='text-xl md:text-3xl text-center'>
                  4.9 / (537)
                </div>
            }
            <div className='sm:text-[20px]  text-[12px] text-[#fdc800] text-center'>Google Rating</div>
          </div>
        </div>
      </ScrollTrigger>
    </div>
  )
}

export default NumberReport
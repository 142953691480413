import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/universitycontext";

function LoanFec({ uni }) {
  if (!uni) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full py-5 bg-[#f2f2f2] px-4 lg:px-0">
      <div className="container mx-auto">
        <h2 className="text-blue-500 py-2 my-2">Education Loan Facility</h2>
        <p>{uni.loanBNK}</p>
        <ul className="pl-0">
          <li>Contact</li>
          <li>Education Loan Coordinator</li>
          <li>
            E-mail: <Link to={`mailto:${uni.email}`}>{uni.email}</Link>
          </li>
          <li>
            Mob : <Link to={`tel:${uni.phone}`}>{uni.phone}</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default LoanFec;

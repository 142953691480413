import React from "react";
import { useState } from "react";

function UniFaq({ uni }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <>
      <section className=" text-white  bg-[#002147]  w-full py-12 pb-[6rem]">
        <div className="container mx-auto">
          <p className="font-bold text-white text-4xl text-center py-5">
            Let`s clear some doubts
          </p>
          <div className="grid  gap-2 rounded-md ">
            {/* right section start */}
            <div className="col-span-2 px-2">
              {/* 1st ques start */}
              <div
                className={`border-2 border-[#7D7C7C] p-4 mb-3 flex items-center ${
                  activeIndex === 0 ? "text-[#1BCCBA]" : "text-[#fff] "
                }`}
                onClick={() => toggleAccordion(0)}
              >
                <span className="hover:text-[0D1282] text-white font-bold text-xl">
                  {uni && uni.doubtSection[0].question}
                </span>
                <span className="text-[#1BCCBA] ml-auto">
                  {activeIndex === 0 ? "-" : "+"}
                </span>
              </div>

              {activeIndex === 0 && (
                <div className="p-2 border mb-3 border-[#7D7C7C]">
                  {/* Content for the 1st question */}
                  <ul className="list-disc px-6 font-light">
                    <li>{uni && uni.doubtSection[0].answer1}</li>
                    {uni && uni.answer2 == null ? (
                      ""
                    ) : (
                      <li>{uni && uni.doubtSection[0].answer2}</li>
                    )}
                  </ul>
                </div>
              )}

              {/* 1st ques end */}
              {/* 2nd ques start */}
              <div
                className={`border-2 border-[#7D7C7C] p-4 mb-3 flex items-center ${
                  activeIndex === 0 ? "text-[#1BCCBA]" : "text-[#fff] "
                }`}
                onClick={() => toggleAccordion(0)}
              >
                <span className="hover:text-[0D1282] text-white font-bold text-xl">
                  {uni && uni.doubtSection[1].question}
                </span>
                <span className="text-[#1BCCBA] ml-auto">
                  {activeIndex === 0 ? "-" : "+"}
                </span>
              </div>

              {activeIndex === 0 && (
                <div className="p-2 border mb-3 border-[#7D7C7C]">
                  {/* Content for the 1st question */}
                  <ul className="list-disc px-6 font-light">
                    <li>{uni && uni.doubtSection[1].answer1}</li>
                    {uni && uni.answer2 == null ? (
                      ""
                    ) : (
                      <li>{uni && uni.doubtSection[1].answer2}</li>
                    )}
                  </ul>
                </div>
              )}
              {/* 2nd ques end */}
              {/* 3rd ques start */}

              <div
                className={`border-2 border-[#7D7C7C] p-4 mb-3 flex items-center ${
                  activeIndex === 0 ? "text-[#1BCCBA]" : "text-[#fff] "
                }`}
                onClick={() => toggleAccordion(0)}
              >
                <span className="hover:text-[0D1282] text-white font-bold text-xl">
                  {uni && uni.doubtSection[2].question}
                </span>
                <span className="text-[#1BCCBA] ml-auto">
                  {activeIndex === 0 ? "-" : "+"}
                </span>
              </div>

              {activeIndex === 0 && (
                <div className="p-2 border mb-3 border-[#7D7C7C]">
                  {/* Content for the 1st question */}
                  <ul className="list-disc px-6 font-light">
                    <li>{uni && uni.doubtSection[2].answer1}</li>
                    {uni && uni.answer2 == null ? (
                      ""
                    ) : (
                      <li>{uni && uni.doubtSection[2].answer2}</li>
                    )}
                  </ul>
                </div>
              )}
              {/* 3rd ques end */}
              {/* 4th ques start */}

              <div
                className={`border-2 border-[#7D7C7C] p-4 mb-3 flex items-center ${
                  activeIndex === 0 ? "text-[#1BCCBA]" : "text-[#fff] "
                }`}
                onClick={() => toggleAccordion(0)}
              >
                <span className="hover:text-[0D1282] text-white font-bold text-xl">
                  {uni && uni.doubtSection[3].question}
                </span>
                <span className="text-[#1BCCBA] ml-auto">
                  {activeIndex === 0 ? "-" : "+"}
                </span>
              </div>

              {activeIndex === 0 && (
                <div className="p-2 border mb-3 border-[#7D7C7C]">
                  {/* Content for the 1st question */}
                  <ul className="list-disc px-6 font-light">
                    <li>{uni && uni.doubtSection[3].answer1}</li>
                    {uni && uni.answer2 == null ? (
                      ""
                    ) : (
                      <li>{uni && uni.doubtSection[3].answer2}</li>
                    )}
                  </ul>
                </div>
              )}
              {/* 4th ques end */}
              {/* 5th ques start */}
              <div
                className={`border-2 border-[#7D7C7C] p-4 mb-3 flex items-center ${
                  activeIndex === 0 ? "text-[#1BCCBA]" : "text-[#fff] "
                }`}
                onClick={() => toggleAccordion(0)}
              >
                <span className="hover:text-[0D1282] text-white font-bold text-xl">
                  {uni && uni.doubtSection[4].question}
                </span>
                <span className="text-[#1BCCBA] ml-auto">
                  {activeIndex === 0 ? "-" : "+"}
                </span>
              </div>

              {activeIndex === 0 && (
                <div className="p-2 border mb-3 border-[#7D7C7C]">
                  {/* Content for the 1st question */}
                  <ul className="list-disc px-6 font-light">
                    <li>{uni && uni.doubtSection[4].answer1}</li>
                    {uni && uni.answer2 == null ? (
                      ""
                    ) : (
                      <li>{uni && uni.doubtSection[4].answer2}</li>
                    )}
                  </ul>
                </div>
              )}
              {/* 5th ques end */}
            </div>
            {/* right section end */}
          </div>
        </div>
        {/* second section end */}
      </section>
    </>
  );
}

export default UniFaq;

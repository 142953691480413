import React from 'react';
import { AiFillCheckCircle } from "react-icons/ai";
import { SiBookstack } from "react-icons/si";
import { BsTrophyFill } from 'react-icons/bs';
import { CiDeliveryTruck } from 'react-icons/ci';


const WhyUs = () => {
    return (
        <div className="w-full px-4 lg:px-0">
            <div className='container mx-auto flex py-12 justify-around flex-wrap'>
                <div className='w-full lg:w-[40%]'>
                    <h3 className='text-4xl py-2 font-semibold'>Why Us</h3>
                    <div className='text-[16px] text-[#686a6f]'>
                        There are movements for education reforms, such as for improving quality and efficiency
                        of education towards applicable relevance in the students’ lives and efficient problem
                        solving in modern or future society at large or for evidence-based education methodologies.
                    </div>
                    <div className='py-3'>
                        <div className='flex py-2 items-center'>
                            <AiFillCheckCircle className='text-[30px] text-[#002147] mr-1' />
                            <div className='text-[16px]'>1000 Online Course</div>
                        </div>
                        <div className='flex  items-center'>
                            <AiFillCheckCircle className='text-[30px] text-[#002147] mr-1' />
                            <div className='text-[16px]'>Expert Instruction</div>
                        </div>
                        <div className='flex py-2 items-center'>
                            <AiFillCheckCircle className='text-[30px] text-[#002147] mr-1' />
                            <div className='text-[16px]'>Lifetime Access</div>
                        </div>
                    </div>
                </div>
                <div className='w-full lg:w-[40%] p-5 text-white bg-[#002147] '>
                    <div className='flex items-center mt-1'>
                        <SiBookstack className='text-[50px] mr-4'/>
                        <div className='relative'>
                            <div className='text-[40px] font-bold ' >520<span style={{ fontSize:"20px" ,position :'absolute' }}>+</span></div>
                            <div className='text-[16px] '>Courses Available</div>
                        </div>
                    </div>
                    <div className='flex items-center mt-3'>
                        <CiDeliveryTruck className='text-[40px]  mr-4' />
                        <div>
                            <div className='text-[40px] font-bold '>250<span style={{ fontSize: "20px", position: 'absolute' }}>+</span></div>
                            <div className='text-[16px] '>Good Delievered</div>
                        </div>
                    </div>
                    <div className='flex items-center mt-3'>
                        <BsTrophyFill className='text-[40px] mr-4' />
                        <div>
                            <div className='text-[40px] font-bold '>451<span style={{ fontSize: "20px", position: 'absolute' }}>+</span></div>
                            <div className='text-[16px] '>Award Archieved</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyUs;


import React, { useState } from 'react';
import { FaUserGraduate, FaUserTie } from 'react-icons/fa';
import { GiFlowerEmblem } from 'react-icons/gi';
import { BsFillTrophyFill } from 'react-icons/bs';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';


const OurFacilties = () => {

    const [count, setCount] = useState(false);

    return (
        <ScrollTrigger onEnter={() => setCount(true)} onExit={() =>setCount(false)}>
            <div  className="py-5">
                <div className="py-5 md:flex bg-[#002147]  justify-around border">
                    <div className='flex md:w-[23%] w-[70%] mx-auto md:mx-0 border-gray-100 border-b md:border-b-0  md:border-r justify-center py-3 gap-4  items-center flex-col'>
                        <div className='flex border-2 w-[45px] justify-center rounded-full items-center border-[#f2cd32] h-[45px]'>
                            <FaUserGraduate className='text-[25px] text-[#f2cd32]' />
                        </div>
                        <div className='text-[35px] font-bold text-[#f2cd32]'>
                           ` {
                                count && <div> <CountUp start={0} end={550} duration={3} delay={0} />+
                                </div>
                            }`
                        </div>
                        <div className='text-[#fff]'>
                            Enrolled Students
                        </div>
                    </div>
                    <div className='flex md:w-[23%] w-[70%] mx-auto md:mx-0  border-gray-100 border-b first-letter md:border-b-0  md:border-r justify-center py-3 gap-4  items-center flex-col'>
                        <div className='flex border-2 w-[45px] justify-center rounded-full items-center border-[#f2cd32] h-[45px]'>
                            <FaUserTie className='text-[25px] text-[#f2cd32]' />
                        </div>
                        <div className='text-[35px] font-bold text-[#f2cd32]'>
                            {
                                count && <div> <CountUp start={0} end={75} duration={3} delay={0} />+
                                </div>
                            }
                        </div>
                        <div className='text-[#fff]'>
                            Instructors
                        </div>
                    </div>
                    <div className='flex md:w-[23%] w-[70%] mx-auto md:mx-0 md:border-b-0 border-gray-100  border-b  md:border-r  justify-center py-3 gap-4  items-center flex-col'>
                        <div className='flex border-2 w-[45px] justify-center rounded-full items-center border-[#f2cd32] h-[45px]'>
                            <GiFlowerEmblem className='text-[25px] text-[#f2cd32]' />
                        </div>
                        <div className='text-[35px] font-bold text-[#f2cd32]'>
                            {
                                count && <div> <CountUp start={0} end={100} duration={3} delay={0} />+
                                </div>
                            }
                        </div>
                        <div className='text-[#fff]'>
                            Certified Courses
                        </div>
                    </div>
                    <div className='flex md:w-[23%] w-[70%] mx-auto md:mx-0  justify-center py-3 gap-4  items-center flex-col'>
                        <div className='flex border-2 w-[45px] justify-center rounded-full items-center border-[#f2cd32] h-[45px]'>
                            <BsFillTrophyFill className='text-[25px] text-[#f2cd32]' />
                        </div>
                        <div className='text-[35px] font-bold text-[#f2cd32]'>
                            {
                                count && <div> <CountUp start={0} end={10} duration={3} delay={0} />+
                                </div>
                            }
                        </div>
                        <div className='text-[#fff]'>
                            Education Award
                        </div>
                    </div>
                </div>
            </div>
        </ScrollTrigger>
    );
}

export default OurFacilties;


import React from 'react';
import { Link } from 'react-router-dom';

const HoverUniversities = () => {
  return (
    <>
      {/* Large Size Listing */}
      <div className='hidden lg:block'>
        <div className="hidden w-full lg:flex py-5 px-5 flex-wrap">
          <ul className="w-[24%] p-2" >
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Master of Technology [M.Tech] (Civil Engineering)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Master of Technology [M.Tech] (Electrical Engineering)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Master of Technology [M.Tech] (Electronics and Communication Engineering)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Master of Technology [M.Tech] (Structural Engineering)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Master of Technology [M.Tech] (Computer Science and Engineering)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Master of Pharmacy [M.Pharm] (Pharmaceutics)</Link>
            </li>
          </ul>
          {/* ====== */}
          <ul className="w-[24%] p-2" >

            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Master of Pharmacy [M.Pharm] (Pharmacology)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Master of Business Administration [MBA] (Information Technology)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Master of Business Administration [MBA] (Finance)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Master of Business Administration [MBA] (Marketing)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Master of Business Administration [MBA] (Human Resource)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Diploma in Pharmacy [D.Pharma]</Link>
            </li>
          </ul>
          {/* ===== */}
          <ul className="w-[24%] p-2" >

            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Bachelor of Technology [B.Tech] [Lateral] (Mechanical Engineering)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Bachelor of Technology [B.Tech] [Lateral] (Information Technology)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Bachelor of Technology [B.Tech] [Lateral] (Electronics & Communication Engineering)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Bachelor of Technology [B.Tech] [Lateral] (Electrical Engineering)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Bachelor of Technology [B.Tech] [Lateral] (Computer Science & Engineering)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Bachelor of Technology [B.Tech] [Lateral] (Computer Science and Design)</Link>
            </li>

          </ul>
          {/* === */}
          <ul className="w-[24%] p-2" >

            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Bachelor of Technology [B.Tech] [Lateral] (Civil Engineering)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Bachelor of Technology [B.Tech] [Lateral] (Artificial Intelligence and Data Science)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Bachelor of Technology [B.Tech] (Computer Science and Design)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Bachelor of Technology [B.Tech] (Artificial Intelligence and Data Science)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Bachelor of Technology [B.Tech] (Electronics & Communication Engineering)</Link>
            </li>
            <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
              <Link to="allUniversity"> Bachelor of Technology [B.Tech] (Electrical Engineering)</Link>
            </li>
          </ul>
        </div>
      </div>
      {/* End Large Size Listing */}

      {/* Mobile View All List */}
      <div className="lg:hidden flex w-full flex-wrap justify-between text-white py-1 bg-[#002147] px-1">
        <ul className="w-[48%]" >
          <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
            <Link to="allUniversity">  M.Tech (Civil Engineering)</Link>
          </li>
          <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
            <Link to="allUniversity">  M.Tech (Electrical Engineering)</Link>
          </li>
          <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
            <Link to="allUniversity">  M.Tech (Electronics and Communication Engineering)</Link>
          </li>
          {/* <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
                      <Link to="allUniversity">  M.Tech (Structural Engineering)</Link>
                    </li>
                    <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
                      <Link to="allUniversity">  M.Tech (Computer Science and Engineering)</Link>
                    </li>
                    <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
                      <Link to="allUniversity"> M.Pharm (Pharmaceutics)</Link>
                    </li> */}
        </ul>
        {/* ====== */}
        <ul className="w-[48%] " >

          <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
            <Link to="allUniversity"> M.Pharm (Pharmacology)</Link>
          </li>
          <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
            <Link to="allUniversity">  MBA (Information Technology)</Link>
          </li>
          <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
            <Link to="allUniversity">  MBA (Finance)</Link>
          </li>
          {/* <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
                      <Link to="allUniversity">  MBA (Marketing)</Link>
                    </li>
                    <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
                      <Link to="allUniversity">  MBA (Human Resource)</Link>
                    </li>
                    <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
                      <Link to="allUniversity"> D.Pharma</Link>
                    </li> */}
        </ul>
        {/* ===== */}
        <ul className="w-[48%]" >

          <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
            <Link to="allUniversity">  B.Tech (Mechanical Engineering)</Link>
          </li>
          <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
            <Link to="allUniversity">  B.Tech (Information Technology)</Link>
          </li>
          <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
            <Link to="allUniversity">  B.Tech (Electronics & Communication Engineering)</Link>
          </li>
          {/* <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
                      <Link to="allUniversity">  B.Tech (Electrical Engineering)</Link>
                    </li>
                    <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
                      <Link to="allUniversity">  B.Tech (Computer Science & Engineering)</Link>
                    </li>
                    <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
                      <Link to="allUniversity">  B.Tech (Computer Science and Design)</Link>
                    </li> */}

        </ul>

        {/* === */}
        <ul className="w-[48%]" >

          <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
            <Link to="allUniversity">  B.Tech (Mechanical Engineering)</Link>
          </li>
          <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
            <Link to="allUniversity">  B.Tech (Information Technology)</Link>
          </li>
          <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
            <Link to="allUniversity">  B.Tech (Electronics & Communication Engineering)</Link>
          </li>
          {/* <li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
  <Link to="allUniversity">  B.Tech (Electrical Engineering)</Link>
</li>
<li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
  <Link to="allUniversity">  B.Tech (Computer Science & Engineering)</Link>
</li>
<li className="text-left font-[400] mb-4 hover:text-[#fdc800]">
  <Link to="allUniversity">  B.Tech (Computer Science and Design)</Link>
</li> */}

        </ul>
        {/* === */}

      </div>
      {/* EnD Mobile View ALl List */}
    </>
  );
}

export default HoverUniversities;

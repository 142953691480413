
import React, { useEffect, useState } from 'react';
import { Circle } from 'rc-progress';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import expertImg from "./image/expert.jpg"
// npm i rc-progress using this package

const OurExperts = () => {
    // const [inc, setInc] = useState(0)
    const [count, setCount] = useState(false);
    const [countTeacher, setCountTeacher] = useState('20');
    const [countCourse, setCountCourse] = useState('73');


   
    return (
        <div className="py-[20px] w-[90%] mx-auto xl:flex justify-between">
            <img
                className='xl:w-[40%] w-[80%] xl:mx-0 mx-auto '
                width={10}
                height={10}
                alt='image not Fount'
                src={expertImg}
                sizes='10vw'
            />
           
            <div className='xl:w-[58%] w-[80%] mx-auto xl:mx-0 flex flex-col py-4'>
                <div className='text-[#48a4d7] font-bold font-[poppins]'>About us</div>
                <div className='xl:text-[58px] text-[34px]  font-bold'>online courses</div>
                <div className='xl:text-[58px]  text-[34px] font-bold xl:leading-[60px] text-[#48a4d7]'>from the experts</div>
                <div className='py-2 text-[18px] xl:text-[28px]  font-[600]'>Edec-Pro is the best education theme that is fully responsive, lightweight.</div>
                <div className='text-[12px] xl:text-[16px] font-[400] text-[#696a6f]'>Education is the process of facilitating learning, or the acquisition of knowledge, skills, values,
                    morals, beliefs, and habits. Educational methods include teaching, training, storytelling, discussion
                    and directed.</div>
                <div className='md:flex py-3 justify-between'>
                    <div className=' w-[160px] mx-auto  md:mt-0 md:mx-0  justify-center flex items-center relative h-[160px] rounded-full'>
                        <Circle
                            percent={countTeacher}
                            strokeColor={"#48a4d7"}
                            strokeWidth={8}
                            trailColor='lightgrey'
                            className=' cursor-pointer'
                            trailWidth={4} />
                        <div className='absolute text-[20px]'>
                            {countTeacher}%
                        </div>
                    </div>
                    <div className='w-[80%] mx-auto md:mx-0 md:w-[75%] flex flex-col justify-center p-3'>
                        <div className='font-bold  text-center text-[16px]  md:text-left '>Teachers</div>
                        <div className='md:text-[16px] py-1 text-[12px] text-center md:text-left '>Education can take place in formal or informal settings
                            and any experience that has a formative.</div>
                    </div>

                </div>
                <div className='md:flex  justify-between'>
                    <div className=' w-[160px] mx-auto  md:mt-0 md:mx-0  justify-center flex items-center relative h-[160px] rounded-full'>
                    <Circle
                        percent={countCourse}
                        strokeColor={"#48a4d7"}
                        strokeWidth={8}
                        trailColor='lightgrey'
                        className=' cursor-pointer'
                        trailWidth={4} />
                    <div className='absolute text-[20px]'>
                        {countCourse}%
                    </div>
                </div>
                <div className='w-[80%] mx-auto md:mx-0 md:w-[75%] flex flex-col justify-center p-3'>
                    <div className='font-bold  text-center text-[16px]  md:text-left '>Courses</div>
                    <div className='md:text-[16px] py-1 text-[12px] text-center md:text-left'>Education can take place in formal or informal settings
                        and any experience that has a formative.
                    </div>
                </div>
            </div>
        </div>
        </div >
    );
}

export default OurExperts;


import React from 'react';
import "./index.css"


const AnimatedText = () => {
  return (
    <a href="https://codepen.io/uiswarup/full/wvjZgar" target="_blank">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h3 className="animate-charcter">Apply now</h3>
          </div>
        </div>
      </div>
    </a>
  );
};

export default AnimatedText;

import React from 'react'
import TopBanner from '../../components/common/topBanner'
import Map from './map/page'
import BottomContact from './bottomContact/page'
import MidContact from './midContact/page'
import FormContact from './formContact/page'

const Contact = () => {
  return (
    <>
     <TopBanner bgBanner="contactBgBanner" title="Contact Us" />
      <Map />
      <BottomContact />
      <FormContact />
      <MidContact />
    </>
  )
}

export default Contact
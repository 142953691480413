import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";

function SearchCourses() {
  return (
    <div className="w-full bg_serach px-4 lg:px-0 flex justify-center" >
      <div className="container py-[140px] mx-auto">
        <h3 className="font-bold text-white text-2xl lg:text-left">Search For Courses</h3>
        <p className="text-[#b2c0d8] text-sm font-thin py-2 lg:text-left ">Our community is being called to reimagine the future. As the only university where a renowned design <br/>school comes together with premier colleges, we are making learning more relevant and transformational.</p>
        <div className="flex justify-center lg:justify-start">
          <form className="w-full md:max-w-[80%] lg:max-w-[60%]  sm:mx-auto md:mx-0 lg:mx-0 ">
            <div className="mb-1 lg:mb-5 flex justify-between flex-wrap md:flex-nowrap">
              <div className="w-full mr-2 md:mr-0 mb-2  lg:w-[48%]">
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="First Name"
                  required
                />
              </div>

              <div className="w-full mr-2 md:mr-0 mb-2  lg:w-[48%]">
                <input
                  type="Email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="LastName"
                  required
                />
              </div>
              
            </div>
            <div className="mb-1 lg:mb-5 flex justify-between flex-wrap md:flex-nowrap">
              <div className="w-full mr-2 md:mr-0 mb-2  lg:w-[48%]">
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Phone"
                  required
                />
              </div>

              <div className="w-full mr-2 md:mr-0 mb-2  lg:w-[48%]">
                <input
                  type="phone"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Course"
                  required
                />
              </div>
              
            </div>


            {/*  Start*/}
            {/* <div className="mb-1 lg:mb-5 flex justify-between flex-wrap md:flex-nowrap">
              <div className="w-full mr-2 md:mr-0 mb-2  lg:w-[48%]">
                <select
                  id="countries"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option>LastName</option>
                  <option>Hot Stone Message</option>
                  <option>Facil & Therophy</option>
                </select>
              </div>

              <div className="w-full mr-2 md:mr-0 mb-2  lg:w-[48%]">
                <select
                  id="countries"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option>Instructor</option>
                  <option>Hot Stone Message</option>
                  <option>Facil & Therophy</option>
                </select>
              </div>
            </div> */}
            {/* EnD */}


            {/*  Start*/}
            {/* <div className="mb-1 lg:mb-5 flex justify-between flex-wrap md:flex-nowrap">
              <div className="w-full mr-2 md:mr-0 mb-2  lg:w-[48%]">
                <select
                  id="countries"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option>Instructor</option>
                  <option>Hot Stone Message</option>
                  <option>Facil & Therophy</option>
                </select>
              </div>

              <div className="w-full mr-2 md:mr-0 mb-2  lg:w-[48%]">
                <select
                  id="countries"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option>Instructor</option>
                  <option>Hot Stone Message</option>
                  <option>Facil & Therophy</option>
                </select>
              </div>
            </div> */}
            {/* EnD */}

            <div className="w-[100%]">
              <button
                type="submit"
                className="text-white text-md w-[100%] bg-red-400 hover:bg-[#125875] focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg  px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 flex gap-3 items-center"
              >
                Search Courses Here
                <FaArrowRightLong />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SearchCourses;
